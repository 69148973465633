let _ = require('lodash/object');

export default  class asyncRequestAdapter {
    options =  {};
    constructor (options) {
        if (typeof options === 'object' && options !== null) {
            this.options = _.merge(this.options, options) ;
        }
        return this;
    }

    get (arg, options) {
        console.log('todo - request get, please override in child classes');
        return 'todo';
    }

    post (arg, data, options) {
        console.log('todo - request post, please override in child classes');
        return 'todo';
    }

    put (arg, data, options) {
        console.log('todo - request put, please override in child classes');
        return 'todo';
    }

    patch (arg, data, options) {
        console.log('todo - request patch, please override in child classes');
        return 'todo';
    }

    parseRespons (response) {
        console.log('todo - request parseRespons, please override in child classes');
        return 'todo';
    }

    parseRequest (arg, data, options) {
        console.log('todo - request parseRequest, please override in child classes');
        return 'todo';
    }

}
